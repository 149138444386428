import { MdAttachEmail } from "react-icons/md";

const data = [
  {
    contactId: 4,
    icon: <MdAttachEmail />,
    link: "mailto:romain.formationoc@gmail.com",
  },
];

export default data;
