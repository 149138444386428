const data = [
  {
    portId: 19,
    category: "HTML/CSS",
    img: "../ohmyfood.webp",
    title: "Oh my food",
    desc: "Site basé uniquement sur la partie intégration en HTML5 / CSS3 / SCSS. Le site est responsive et a été créé avec des animations. Projet Openclassrooms.",
    link: "https://romtouf.github.io/Oh-my-food/",
    github: "https://github.com/Romtouf/Oh-my-food",
  },
  {
    portId: 20,
    category: "React js",
    img: "../kasa.webp",
    title: "Kasa",
    desc: "Ce projet a été réalisé dans le cadre de la formation Développeur Web d'Openclassrooms. L'objectif était de créer un site de location de logements. Le site a été créé avec React js.",
    link: "https://github.com/Romtouf/Kasa",
    github: "https://github.com/Romtouf/Kasa",
  },
  {
    portId: 21,
    category: "IA",
    img: "../simple-meteo-app.png",
    title: "Simple app météo créée avec l'IA",
    desc: "Application météo simple créée avec l'IA. L'application utilise l'API OpenWeatherMap pour obtenir les données météorologiques. L'application a été créée avec React js et l'IA. Le but de ce projet est de découvrir l'IA et son apport sur le développement d'une application.",
    link: "https://simple-test-app-meteo-created-with-ai.netlify.app/",
    github: "https://github.com/Romtouf/Simple-meteo-app-100-AI",
  },
  {
    portId: 22,
    category: "SEO",
    img: "../pbernard.webp",
    title: "Massothérapeute - Pierre Bernard",
    desc: "Site web créé pour un massothérapeute. Le site a été créé avec une attention particulière à l'optimisation SEO. Le site est responsive et respecte l'accessibilité. Le client souhaitait un site simple et épuré pour présenter ses services, ses coordonnées et de la visibilité. Site réalisé avec HTML5 / CSS3 / JavaScript / SEO.",
    link: "https://pierre-bernard.fr/",
    github: "https://github.com/Romtouf/pierre-bernard",
  },
  {
    portId: 23,
    category: "Gestion de projet",
    img: "../qwenta.webp",
    title: "Menu maker by Qwenta",
    desc: "Cette réalisation est basée sur la gestion de projet. Celui-ci a été créé avec une méthode agile, des spécifications techniques, une veille technologique, une solution technique.",
    link: "https://github.com/Romtouf/Menu_Maker_by_Qwenta",
    github: "https://github.com/Romtouf/Menu_Maker_by_Qwenta",
  },
  {
    portId: 24,
    category: "React js",
    img: "../lcf-live.png",
    title: "LCF Live",
    desc: "Site en construction. LCF Live est un site de gestion des utilisateurs qui auront la possibilité de regarder les matchs en direct de leur équipe préférée et d'interagir via un live chat. Le site est créé avec React js, Node js et Firestore.",
    link: "https://github.com/Romtouf/lcf-live",
    github: "https://github.com/Romtouf/lcf-live",
  },
];

export default data;
